import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import ethereum from "../../assets/images/Crypto/ethereum.png";
import bitcoinone from "../../assets/images/Crypto/bitcoin.png";
import litecoin from "../../assets/images/Crypto/litecoin.png";
import { FaCopy } from "react-icons/fa";
import { MdQrCode2 } from "react-icons/md";
import depimage from "../../assets/images/Crypto/b2b.webp";
import QRCode from "react-qr-code";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { makeApiRequest } from "../../config/AxiosService";
import config from "../../config/Config";
import { toast } from "react-toastify";

const cryptoData = [
  { img: ethereum, textOne: "ETH", textTwo: "Ethereum" },
  { img: bitcoinone, textOne: "BTC", textTwo: "Bitcoin" },
  { img: litecoin, textOne: "SOL", textTwo: "Solana" },
  { img: litecoin, textOne: "LTC", textTwo: "Litecoin" },
  { img: litecoin, textOne: "ADA", textTwo: "Cardano" },
];

export default function Withdraw() {
  const navigate = useNavigate();
  const evmAddressRegex = /^0x[a-fA-F0-9]{40}$/;
  const btcAddressRegex = config.btcRegex
  const solanaAddressRegex = /^[1-9A-HJ-NP-Za-km-z]{44}$/;
  const ltcAddressRegex = config.ltcRegex
  const adaAddressRegex = config.adaRegex
  const [text, setText] = useState("");
  const [selected, setSelected] = useState(cryptoData[0]);
  const [toAddress, setToAddress] = useState("")
  const [recieveAmnt, setRecieveAmnt] = useState(0)
  const [errors, setErrors] = useState("")
  const [userBalance, setUserBalance] = useState({
    USDT_Balance: 0,
    ETH_Balance: 0,
    BTC_Balance: 0,
    SOL_Balance: 0,
    LTC_Balance: 0,
    CARDONA_Balance: 0,
  })

  useEffect(() => {
    getBalance()
  }, [])

  async function getBalance() {
    try {
      let params = {
        url: "user-getBalance",
        method: "GET",
      }
      const response = await makeApiRequest(params)
      if (response.status) {
        const resp = response.data
        setUserBalance((...prevBal) => (prevBal, {
          ETH_Balance: resp.ETH_Balance,
          BTC_Balance: resp.BTC_Balance,
          SOL_Balance: resp.SOL_Balance,
          LTC_Balance: resp.LTC_Balance,
          CARDONA_Balance: resp.CARDONA_Balance,
        }))
      } else {
        setUserBalance(0)
      }
    } catch (error) {
      console.log("Internal Error..");
    }
  }

  async function handleSubmit() {
    try {
      let params = {
        url: "create-user-withdraw-request",
        method: "POST",
        data: {
          toAddress: toAddress.address,
          amount: recieveAmnt.amount,
          Symbol: selected.textOne
        }
      }
      const response = await makeApiRequest(params)
      if (response.status) {
        toast.success(response.message)
        navigate("/crypto")
      } else {
        toast.warn(response.message)
        navigate("/withdraw")
      }
    } catch (error) {
      console.log("Internal Error..");
    }
  }

  async function handleChange(e) {
    const { name, value } = e.target;

    const addressRegexMap = {
      ETH: evmAddressRegex,
      BTC: btcAddressRegex,
      SOL: solanaAddressRegex,
      LTC: ltcAddressRegex,
      ADA: adaAddressRegex,
    };

    setToAddress({ [name]: value });

    const regex = addressRegexMap[selected.textOne];
    setErrors({ [name]: regex && !regex.test(value) ? `Invalid ${selected.textOne} address format` : "" });
  }

  async function handleAmount(e) {
    const { name, value } = e.target;

    const balance = {
      ETH: userBalance.ETH_Balance,
      BTC: userBalance.BTC_Balance,
      SOL: userBalance.SOL_Balance,
      LTC: userBalance.LTC_Balance,
      ADA: userBalance.CARDONA_Balance,
    };

    setRecieveAmnt((prev) => ({ ...prev, [name]: value }));
    const allowedBalance = balance[selected.textOne];

    setErrors({ [name]: Number(allowedBalance) < Number(value) ? `Enter the Amount below ${allowedBalance}` : "" });
  }

  return (
    <>
      <section className="deposit-section">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="mb-auto tittle-tx1">Withdraw crypto</p>
              </div>
              {/* <div className="d-flex me-2">
              <Link to="" className="dep-btn w-100">
              Withdraw flat
                <MdKeyboardArrowRight className="arrow-right ms-auto arrow-right-1" />
              </Link>
            </div> */}
            </div>
          </div>
          <div className="row mt-4 deposit-col">
            <div className="col-lg-6 col-12  pt-3">
              <div className="ms-lg-2 mb-4">
                <p
                  className="tittle-tx "
                >
                  Select a coin/token
                </p>
                {selected && (
                  <div className="d-flex align-items-center justify-content-between input-div" data-bs-toggle="modal"
                    data-bs-target="#selecttokenModal">

                    <div>
                      <img src={selected.img} className="deposit-img" /> <span> {selected.textOne}</span>
                    </div>
                    <div className="text-center">
                      <IoMdArrowDropdown />
                    </div>

                  </div>
                )}
                {/* <!-- token Modal --> */}
                <div
                  class="modal fade"
                  id="selecttokenModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  data-bs-backdrop="static" data-bs-keyboard="false"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="tittle-tx fs-5" id="exampleModalLabel">
                          Select Coin/Token
                        </h1>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body pt-0">
                        {cryptoData.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex gap-2 list-div py-2"
                            onClick={() => setSelected(item)}
                            style={{ cursor: "pointer" }}
                            data-bs-dismiss="modal"
                          >
                            <div>
                              <img src={item.img} className="deposit-img" alt={item.textTwo} />
                            </div>
                            <div>
                              <p className="mb-0 text-one">{item.textOne}</p>
                              <p className="mb-0 text-two">{item.textTwo}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-lg-2 mb-4">
                <p className="tittle-tx mb-2">Send to</p>
                <div className="">
                  <input
                    type="text"
                    name="address"
                    class="form-control"
                    id="address"
                    placeholder="Enter Your Wallet Address"
                    onChange={handleChange}
                  />
                  {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>}
                </div>
              </div>
              <div className="ms-lg-2 mb-4">
                <p className="tittle-tx mb-2">Amount</p>
                <div className="">
                  <input
                    type="number"
                    name="amount"
                    class="form-control"
                    id="amount"
                    placeholder="Enter the Amount"
                    onChange={handleAmount}
                  />
                  {errors.amount && <p style={{ color: 'red' }}>{errors.amount}</p>}
                </div>
              </div>
              <div className="pt-3">
                <button className="dep-submit-btn mb-3 ms-lg-2 w-100" onClick={handleSubmit}>Withdraw</button>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
