import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { makeApiRequest } from '../../../config/AxiosService';
import Legalentity from './Legalentity';
import Individual from './Individual';
import Ubo from './Ubo';
import { useNavigate } from 'react-router-dom';
import DataContext from "../../../context/DataContext"

function Useraccountform() {
  const [step, setStep] = useState(1);
  const [userStatus, setuserStatus] = useState({})
  const [loggedin, setLoggedIn] = useState(false)
  const { setIsLogedIn } = useContext(DataContext);
  const navigate = useNavigate();

  const toggleLogin = () => {
    setIsLogedIn(true);
    navigate('/');
  };

  useEffect(() => {
    const getFormData = async () => {
      try {
        let params = {
          url: "user-individual-data",
          method: "GET"
        }
        const resp = await makeApiRequest(params)
        if (resp.status) {
          if (resp == null) {
            setuserStatus({})
          } else {
            setuserStatus(resp.resp.individualStatus)
          }

        } else {
          setuserStatus({})
        }
      } catch (error) {
        setuserStatus({})
        // toast("Network Errors...")
      }
    }
    getFormData()
  }, [])

  useEffect(() => {
    if (userStatus > 1) {
      toggleLogin()
    }
  }, [userStatus])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step]);

  const handleNext = (datas) => {
    setStep(prevStep => prevStep + 1);
    if (datas.data == 2) {
      toggleLogin()
    }
  };

  const handlePrevious = () => {
    setStep(prevStep => prevStep - 1);
  };

  const handleParentSubmit = async (values) => {
    try {
      setLoggedIn(true)
      let params = {
        url: "user-registerForm",
        method: "Post",
        data: values,
        header: "image"
      }
      const legalResp = await makeApiRequest(params)
      if (legalResp.status == true) {
        setLoggedIn(false)
        toast.success(legalResp.message)
        setTimeout(() => {
          handleNext(legalResp)
        }, 2000);
      } else {
        setLoggedIn(false)
        toast.error(legalResp.message)
      }
    } catch (error) {
      setLoggedIn(false)
      toast("Network Error...")
    }
  };

  return (
    <div className='Useraccountform py-5'>
      <div className='bef-login-home-sec py-5'>
        <div className='container justify-content-center form-section'>
          <div className='row'>
            <div className='col-lg-10 mx-auto'>
              {step === 1 && (
                <Individual
                  loggedin={loggedin}
                  handleParentSubmit={handleParentSubmit}
                />
              )}
              {step === 2 && (
                <Legalentity
                  loggedin={loggedin}
                  handlePrevious={handlePrevious}
                  handleParentSubmit={handleParentSubmit}
                />
              )}
              {step === 3 && (
                <Ubo
                  loggedin={loggedin}
                  handlePrevious={handlePrevious}
                  handleParentSubmit={handleParentSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default Useraccountform;
