import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import config from './Config';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(config.backendurl, {
      transports: ["websocket"],
      debug: false,
    });
    
    setSocket(newSocket);

    return () => newSocket.disconnect();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
