import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import React, { useState } from "react";
import Select from "react-dropdown-select";
import ethereum from "../../assets/images/Crypto/ethereum.png";
import bitcoinone from "../../assets/images/Crypto/bitcoin.png";
import litecoin from "../../assets/images/Crypto/litecoin.png";
import { FaCopy } from "react-icons/fa";
import { MdQrCode2 } from "react-icons/md";
import depimage from "../../assets/images/Crypto/b2b.webp";
import QRCode from "react-qr-code";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { makeApiRequest } from "../../config/AxiosService"


const cryptoData = [
  { img: ethereum, textOne: "ETH", textTwo: "Ethereum" },
  { img: bitcoinone, textOne: "BTC", textTwo: "Bitcoin" },
  { img: litecoin, textOne: "SOL", textTwo: "Solana" },
  { img: litecoin, textOne: "LTC", textTwo: "Litecoin" },
  { img: litecoin, textOne: "ADA", textTwo: "Cardano" },
];


export default function Deposit() {
  const [text, setText] = useState("");
  const [selected, setSelected] = useState(cryptoData[0]);
  const [address, setAddress] = useState("");
  const [deposit, setDeposit] =useState("")
  // console.log('selected', selected)

  const addressCreate = async (symbol) => {
    if (!selected) return alert("Please select a coin/token first!");

    try {
      let params = {
        url: "createAddress",
        method: "POST",
        withCredentials: true,
        data: { symbol: symbol },
      };
      // console.log('data', data)
      // console.log('params', params)
      const response = await makeApiRequest(params);
      // console.log("response--", response);

      if (response && response.address) {
        setAddress(response.address);
      } else {
        setAddress("Failed to generate address");
      }
    } catch (error) {
      console.log("Cannot get the data", error);
      setAddress("Error fetching address");
    }
  };

  const CoinDeposit = async () => {
    if (!selected) return alert("Please select a coin/token first!");

    try {

      let params = {
        url: "coinDeposit",
        method: "POST",
        withCredentials: true,
        data: { symbol: selected.textOne },
      };
      // console.log('selected---', selected)
      console.log('params', params)
      const response = await makeApiRequest(params);
      console.log("response--", response);

      if (response) {
        setDeposit(response);
      } else {
        setDeposit("Failed to Deposit");
      }
    } catch (error) {
      console.log("Cannot get the data", error);
      setAddress("Error fetching address");
    }
  };

  return (
    <>
      <section className="deposit-section">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="mb-auto tittle-tx1">Deposit crypto</p>
              </div>
              {/* <div className="d-flex me-2">
                <Link to="" className="dep-btn w-100">
                  Deposit flat
                  <MdKeyboardArrowRight className="arrow-right ms-auto arrow-right-1" />
                </Link>
              </div> */}
            </div>
          </div>
          <div className="row mt-4 deposit-col">
            <div className="col-lg-6 col-12  pt-3">
              <div className="ms-lg-2">
                <p
                  className="tittle-tx "
                >
                  select a coin/token
                </p>
                {selected && (
                  <div className="d-flex align-items-center justify-content-between input-div" data-bs-toggle="modal"
                    data-bs-target="#selecttokenModal">

                    <div>
                      <img src={selected.img} className="deposit-img" /> <span> {selected.textOne}</span>
                    </div>
                    <div className="text-center">
                      <IoMdArrowDropdown />
                    </div>

                  </div>
                )}

                <div
                  class="modal fade"
                  id="selecttokenModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  data-bs-backdrop="static" data-bs-keyboard="false"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="tittle-tx fs-5" id="exampleModalLabel">
                          Select Coin/Token
                        </h1>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body pt-0">
                        {cryptoData.map((item, index) => (
                          <div
                            key={index}
                            className="d-flex gap-2 list-div py-2"
                            onClick={() => { setSelected(item); addressCreate(item.textOne) }}
                            style={{ cursor: "pointer" }}
                            data-bs-dismiss="modal"
                          >
                            <div>
                              <img src={item.img} className="deposit-img" alt={item.textTwo} />
                            </div>
                            <div>
                              <p className="mb-0 text-one">{item.textOne}</p>
                              <p className="mb-0 text-two">{item.textTwo}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ms-lg-2 pt-3">
                <p className="tittle-tx mb-2">Address</p>
                <div className="d-lg-flex align-items-center justify-content-between">
                  <span className="text-two">
                    {address}

                  </span>
                  <div className="mb-2">
                    <FaCopy />{" "}
                    <MdQrCode2
                      data-tooltip-id="qr-tooltip"
                      data-tooltip-place="right"

                    />
                  </div>
                </div>

                <Tooltip id="qr-tooltip" style={{ background: "#F6F6F6" }}>
                  <p className="text-one"> QR CODE </p>
                  <QRCode value={text} size={120} />
                </Tooltip>
              </div>
              <div className="">
                <p className="tittle-tx  ms-lg-2">Notes</p>
                <div className="d-flex gap-2 ">
                  <div>
                    <GoDotFill className="list-icon" />
                  </div>
                  <p className="text-two">
                    Minimum deposit of this or token is{" "}
                    <span className="text-one">09897876 BNB </span>. deposit
                    below this amount cannot be recovered.
                  </p>
                </div>
                <div className="d-flex gap-2 ">
                  <div>
                    <GoDotFill className="list-icon" />
                  </div>
                  <p className="text-two">
                    Send only <span className="text-one">BNB </span>to this
                    deposit address.
                  </p>
                </div>
                <div className="d-flex gap-2 ">
                  <div>
                    <GoDotFill className="list-icon" />
                  </div>
                  <p className="text-two">
                    After deposit kindly refresh after{" "}
                    <span className="text-one"> 5-10 minutes </span>to see your
                    deposit
                  </p>
                </div>

                <div>
                  <button className="dep-submit-btn mb-3 ms-lg-2" onClick={CoinDeposit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
