import React, { useContext, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { useFormik } from "formik"
import * as Yup from "yup"
import { makeApiRequest } from "../../config/AxiosService"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner, Modal, Button } from 'react-bootstrap';
import DataContext from "../../context/DataContext"
import { FaRegQuestionCircle } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

function Signin() {
    const navigate = useNavigate();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [navigateTo, setNavigateTo] = useState(''); 
    const { setIsLogedIn } = useContext(DataContext);

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = (path) => {
        setNavigateTo(path);
        setShowModal(true);
    };

    const getFormData = async () => {
        try {
            let params = {
                url: "user-formStatusData",
                method: "GET"
            }
            const resp = await makeApiRequest(params);
            if (resp.status) {
                if (resp.data && Object.keys(resp.data).length > 0) {
                    if (resp.data.individualData?.individualStatus === 2) {
                        setIsLogedIn(true);
                        navigate('/');
                        return;
                    } else if (resp.data.sdk_verification?.sdkStatus === "completed") {
                        handleModalShow("/useraccountform");
                    } else if (resp.data.kyc_Status > "0") {
                        handleModalShow("/accountsection");
                    } else {
                        navigate("/kycdocuments");
                    }
                }
            } else {
                setTimeout(() => {
                    navigate("/kycdocuments");
                }, 2000);
            }
        } catch (error) {
            toast("Network Error...");
        }
    };

    function capitalize(getUserName) {
        return getUserName.charAt(0).toUpperCase() + getUserName.slice(1).toLowerCase();
    }

    const getData = async () => {
        try {
            let params = {
                url: "user-userData",
                method: "GET"
            }
            const response = await makeApiRequest(params);
            console.log('response', response)
            if (response.status) {
                const userName = response.resp.first_name;
                localStorage.setItem("First_name", capitalize(userName));
            } else {
                localStorage.setItem("First_name", "--");
            }
        } catch (error) {
            console.log("cannot get the data");
        }
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Enter a valid email").required("Email is required"),
            password: Yup.string("Enter your password").required("Password is required"),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('email', values.email.toLowerCase());
            formData.append('password', values.password);
            try {
                setIsLoading(true);
                const params = {
                    url: "user-signin",
                    method: "POST",
                    data: formData
                };
                const loginResp = await makeApiRequest(params);
                if (loginResp.status) {
                    localStorage.setItem("email", values.email.toLowerCase());
                    localStorage.setItem("userCredentials", loginResp.token);
                    setIsLoading(false);
                    toast.success(loginResp.message);
                    await getData();
                    await getFormData();
                } else {
                    setIsLoading(false);
                    toast.error(loginResp.message);
                }
            } catch (error) {
                setIsLoading(false);
                toast.error("Internal Server Error");
            }
        }
    });

    return (
        <div className='bef-login-home-sec'>
            <section className='bef-signin-h-sec py-5'>
                <div className='container'>
                    {/* <ToastContainer /> */}
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='card dash-card-1'>
                                <div className='card-body'>
                                    <h5 class="dash-head-1 mb-2">Sign in</h5>
                                    <p class="mb-0 dash-trans-txt-2 mb-4">Get an account and control your finances better with full control of your budgets and savings.</p>
                                    <form className='profile-section-one' onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="john@email.com"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text-danger rq-msg">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3 position-relative">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <div className="input-group">
                                                <input
                                                    type={showOldPassword ? 'text' : 'password'}
                                                    className="form-control password-input"
                                                    id="password"
                                                    placeholder="**********"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    style={{ borderRadius: "0.375rem" }}
                                                />
                                                <span className="password-toggle-icon" onClick={toggleOldPasswordVisibility}>
                                                    {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                                                </span>
                                            </div>
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className="text-danger rq-msg">{formik.errors.password}</div>
                                            ) : null}
                                        </div>

                                        <div class="mb-3 d-flex align-items-center gap-2">
                                            <Link to="/forgetpassword" className='ms-auto link-s-l-1'>Forgot Password ?</Link>
                                        </div>
                                        <div className="mt-4 mb-3">
                                            {isLoading ? (
                                                <button className="d-send-btn-1 rounded-pill" type="button">
                                                    <Spinner animation="border" />
                                                </button>
                                            ) : (
                                                <button className="d-send-btn-1 rounded-pill" type="submit">Sign In</button>
                                            )}
                                        </div>
                                        <div className="mb-3 d-flex align-items-center gap-2 justify-content-end">
                                            <label className="form-label mb-0">Do not have an Account?</label>
                                            <Link to="/signup" className="link-s-l-1">Sign Up</Link>
                                        </div>

                                        {/* React-Bootstrap Modal */}
                                        <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Welcome Back!</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body className="text-center fw-semibold">
                                                <FaRegQuestionCircle className="option-css resume-que" />
                                                Welcome back <span className='option-css'>{localStorage.getItem("First_name")}</span>, resume your account creation process with us.
                                            </Modal.Body>
                                            <Modal.Footer className="justify-content-center">
                                                <Button variant="primary" className="rounded-pill w-50" onClick={() => { 
                                                    navigate(navigateTo);
                                                    handleModalClose(); 
                                                }}>
                                                    Click here to proceed
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Signin;
