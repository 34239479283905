import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2';
import tick from "../../assets/images/home/tick.png";
import { Link } from 'react-router-dom';
import videocall from "../../assets/images/home/videocall.png";
import Select from 'react-select';
import { useFormik } from "formik"
import * as Yup from "yup"
import { makeApiRequest } from "../../config/AxiosService"
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { Country, State, City } from 'country-state-city';
import 'bootstrap/dist/css/bootstrap.min.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { jwtDecode } from 'jwt-decode';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Accountsection() {
  const navigate = useNavigate()
  const [kycData, setKycData] = useState([])
  const [country, setCountry] = useState('us');
  const [countryCode, setCountryCode] = useState("")
  const [phone, setPhone] = useState("" || kycData?.phone_number?.[0]?.number);
  const [verifyToken, setVerifyToken] = useState("")
  const [verifyOtp, setverifyOTP] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [kycStatus, setKycStatus] = useState("")
  const [timer, setTimer] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rescountry, setRescountry] = useState("")
  const [city, setCity] = useState("")
  const [state, setstate] = useState("")
  const [sdkLink, setSdkLink] = useState('');
  const [isLinkGenerated, setIsLinkGenerated] = useState(false);

  const getKycData = async () => {
    try {
      let params = {
        url: "user-kycData",
        method: "GET",
      };
      await makeApiRequest(params)
        .then((resp) => {
          const response = resp.resp;
          setKycData(response);
          setKycStatus(response.kyc_Status);
          if (response.phone_number[0].phoneNo_verify == true) {
            setIsPhoneVerified(true)
          } else {
            setIsPhoneVerified(false)
          }

          // Phone Number 
          if (response?.phone_number?.length > 0) {
            setPhone(
              response.phone_number?.[0]?.[0]?.number ||
              response.phone_number?.[0]?.number
            );
            // setIsPhoneVerified(
            //   response.phone_number?.[0]?.[0]?.phoneNo_verify ||
            //   response.phone_number?.[0]?.phoneNo_verify
            // );
          }

          // Country 
          if (response?.current_Address?.length > 0) {
            const country = Country.getAllCountries().find(
              (country) =>
                country.name ===
                (response.current_Address?.[0]?.[0]?.resident_country ||
                  response.current_Address?.[0]?.resident_country)
            );

            if (country) {
              setRescountry({ value: country.isoCode, label: country.name });
              setSelectedCountry(country);
            }

            // State 
            const state = State.getStatesOfCountry(country?.isoCode || "").find(
              (state) =>
                state.name ===
                (response.current_Address?.[0]?.[0]?.state ||
                  response.current_Address?.[0]?.state)
            );

            if (state) {
              setstate({ value: state.isoCode, label: state.name });
              setSelectedState(state);
            }

            // City 
            const city = City.getCitiesOfState(
              country?.isoCode,
              state?.isoCode
            ).find(
              (city) =>
                city.name ===
                (response.current_Address?.[0]?.[0]?.city ||
                  response.current_Address?.[0]?.city)
            );

            if (city) {
              setCity({ value: city.name, label: city.name });
            }
          }
        })
        .catch(() => {
          setKycData([]);
          if (kycStatus > "1") {
            toast.error("Cannot get the Next page");
          }
        });
    } catch (error) {
      toast.error("Something Wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getKycData();
    };

    fetchData();
  }, []);

  const kycVerifyStatus = async () => {
    let params = {
      url: "user-verifyStatus",
      method: "POST"
    }
    const statusVerify = await makeApiRequest(params)
    if (statusVerify.status) {
      navigate("/useraccountform");
      handleCloseModal();
    } else {
      console.log("response--msg---", statusVerify.message);
    }
  }

  // useEffect(() => {
  //   kycVerifyStatus()
  // }, [])

  // useEffect(() => {
  //   if (kycStatus > "2") {
  //     navigate("/useraccountform");
  //   }
  // }, [kycStatus]);

  const startTokenTimer = (token) => {
    const decodedToken = jwtDecode(token);

    const currentTime = Math.floor(Date.now() / 1000);
    const timeToExpiry = decodedToken.exp - currentTime;
    if (timeToExpiry > 0) {
      setTimer(timeToExpiry);
    } else {
      console.log("Token Expired");
    }
  };

  const placeholders = {
    us: '(201) 555-0123',
    gb: '07400 123456',
    fr: '06 12 34 56 78',
    de: '0151 23456789',
    in: '091234 56789',
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const formik = useFormik({
    initialValues: {
      phone_number: [{
        number: "" || kycData?.phone_number?.[0]?.number,
        phoneNo_verify: false || kycData?.phone_number?.[0]?.phoneNo_verify,
      }],
      current_Address: [{
        address_1: "" || kycData?.phone_number?.[0]?.address_1,
        address_2: "" || kycData?.phone_number?.[0]?.address_2,
        resident_country: "" || kycData?.phone_number?.[0]?.resident_country,
        state: "" || kycData?.phone_number?.[0]?.state,
        city: "" || kycData?.phone_number?.[0]?.city,
        zip_code: "" || kycData?.phone_number?.[0]?.zip_code,
      }],
      kyc_Status: 2,
      document: false,
      consent: false,
    },
    validationSchema: Yup.object({
      // phone_number: Yup.array().of(
      //   Yup.object({
      //     number: Yup.string().required("Enter you Phone Number"),
      //     phoneNo_verify: Yup.boolean().oneOf([true], "Verify and Click this is your Number"),
      //   })
      // ),
      current_Address: Yup.array().of(
        Yup.object({
          address_1: Yup.string().required("Enter Your Apt.No or House Address"),
          address_2: Yup.string().optional(),
          resident_country: Yup.string().required("Select Your Resident Country"),
          state: Yup.string().required("Select the Provinces"),
          city: Yup.string().required("Select Your City"),
          zip_code: Yup.string().required("Enter Your ZIP Code"),
        })
      ),
      document: Yup.boolean().oneOf([true], "You must provide an identity document"),
      consent: Yup.boolean().oneOf([true], "You must consent to terms and privacy policy")
    }),
    onSubmit: async (values) => {
      console.log('values', values)
      if (!isPhoneVerified) {
        toast.error("Please verify your phone number before proceeding.");
        return;
      }
      const formData = new FormData();
      formData.append("kyc_Status", values.kyc_Status);
      formData.append("phone_number[0].number", `${phone}`);
      formData.append("phone_number[0].phoneNo_verify", isPhoneVerified);
      formData.append("current_Address[0].address_1", values.current_Address[0].address_1 || kycData.current_Address?.[0]?.address_1);
      formData.append("current_Address[0].address_2", values.current_Address[0].address_2 || "");
      formData.append("current_Address[0].resident_country", values.current_Address[0].resident_country);
      formData.append("current_Address[0].state", values.current_Address[0].state);
      formData.append("current_Address[0].city", values.current_Address[0].city);
      formData.append("current_Address[0].zip_code", values.current_Address[0].zip_code);

      try {
        setIsLoading(true);
        const params = {
          url: "user-kyc-verification",
          method: "POST",
          data: formData
        };
        const kycResp = await makeApiRequest(params);
        if (kycResp.status == true) {
          toast.success("Account Data Created")
          setIsLoading(false);
          // setTimeout(() => {
          //   navigate("/useraccountform");
          // }, 2000);
          const modalElement = document.getElementById('videocall');
          const modal = new bootstrap.Modal(modalElement);
          modal.show();
        }
      } catch (error) {
        toast.error("Internal Server Error");
        setIsLoading(false);
      }
    }
  });


  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCloseModal = () => setIsModalVisible(false);
  const handleShowModal = () => setIsModalVisible(true);

  const GenerateSDKLink = async () => {
    document.getElementById("videoClosebuton").click()
    handleShowModal()
    try {
      let params = {
        url: "user-generateSDK",
        method: "POST"
      }
      const respLink = await makeApiRequest(params)
      if (respLink.status) {
        const sdkLinkUrl = respLink.sdkLink.url
        if (sdkLinkUrl.startsWith('https')) {
          //   window.open(sdkLinkUrl, '_blank');
          setSdkLink(sdkLinkUrl);
          console.log('sdkLinkUrl--', sdkLinkUrl)
          setIsLinkGenerated(true);
        } else {
          navigate(`/accountsection/${sdkLinkUrl}`);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
  }




  useEffect(() => {
    if (kycData) {
      formik.setValues({
        ...formik.values,
        current_Address: [{
          address_1: kycData.current_Address?.[0]?.address_1 || "",
          address_2: kycData.current_Address?.[0]?.address_2 || "",
          resident_country: kycData.current_Address?.[0]?.resident_country || "",
          state: kycData.current_Address?.[0]?.state || "",
          city: kycData.current_Address?.[0]?.city || "",
          zip_code: kycData.current_Address?.[0]?.zip_code || "",
        }],
      });
    }
  }, [kycData]);

  const otpSubmit = async (showModal = true) => {
    // if (`+${phone}` === "+1") {
    //   return toast.error("Please enter your phone number");
    // }
    if (!formik.values.phone_number?.[0]?.phoneNo_verify) {
      return toast.error("Please verify and accept that this is your number");
    }
    document.getElementById("dropone1close").click();
    setLoading(true);
    try {
      let params = {
        url: "user-mobile-otp",
        method: "POST",
        data: { phone: `+${phone}` }
      };
      const mobOtp = await makeApiRequest(params);

      if (mobOtp.status === true) {
        startTokenTimer(mobOtp.token);
        setVerifyToken(mobOtp.token);
        toast.success(mobOtp.message);
        setverifyOTP("")

        if (showModal && document.querySelector('.modal')) {
          const modalElement = document.getElementById('getopt');
          const modal = new bootstrap.Modal(modalElement);
          modal.show();
        }
      } else {
        setverifyOTP("")
        toast.error(mobOtp.message);
      }
    } catch (error) {
      toast.error("Network error...");
    } finally {
      setLoading(false);
    }
  };

  const verifyMobile_otp = async () => {
    try {
      let params = {
        url: "user-verifyPhone-otp",
        method: "POST",
        data: { verifyToken, verifyOtp }
      }
      const smsOtp = await makeApiRequest(params)
      if (smsOtp.status == true) {
        setIsPhoneVerified(true);
        toast.success(smsOtp.message)
        document.getElementById("otpclosebutton").click();
      } else {
        setverifyOTP("")
        toast.error(smsOtp.message)
      }
    } catch (error) {
      toast("Internal Error..")
    }
  }

  const findCountryByName = (name) => {
    return Country.getAllCountries().find((country) => country.name === name);
  };

  const findStateByName = (name, countryIsoCode) => {
    return State.getStatesOfCountry(countryIsoCode).find((state) => state.name === name);
  };

  const findCityByName = (name, countryIsoCode, stateIsoCode) => {
    return City.getCitiesOfState(countryIsoCode, stateIsoCode).find((city) => city.name === name);
  };

  const verifyPhno = () => {
    if (!phone || phone === "" || phone === countryCode) {
      return toast.error("Please enter your phone number");
    } else {
      const modalElement = document.getElementById('dropone');
      const modal = new bootstrap.Modal(modalElement);
      modal.show();
    }
  };

  return (
    <div className='accountsection'>
      <div className='bef-login-home-sec py-5'>
        <div className='container'>
          {/* <ToastContainer /> */}
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='card dash-card-1'>
                <div className='card-body'>
                  <h5 className='dash-head-1 mb-5 text-center'>Account</h5>
                  <h5 className='dash-head-1 mb-3'>What is your mobile Number</h5>
                  <form className='profile-section-one' onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                      <label htmlFor="number" className="form-label">Phone Number</label>
                      <div className='d-flex align-items-lg-center gap-3 flex-column flex-lg-row'>
                        <PhoneInput
                          country={country}
                          value={phone || kycData?.phone_number?.[0]?.number}
                          onChange={(value, countryData) => {
                            setCountryCode(countryData.dialCode)
                            setPhone(value);
                            setCountry(countryData.countryCode);
                            formik.setFieldValue('phone_number[0].number', value || formik.values.phone_number[0].number);
                          }}
                          placeholder={placeholders[country] || 'Enter phone number'}
                          inputProps={{
                            name: 'phone_number[0].number',
                            id: 'number',
                            className: 'form-control',
                          }}
                        />
                        <div>
                          <button
                            type='button' className='btn opt-btn' id='verifyOtp'
                            // onClick={() => otpSubmit(true)}
                            disabled={isPhoneVerified == true ? true : false}
                            onClick={verifyPhno}
                          // data-bs-toggle="modal" data-bs-target="#dropone"
                          >
                            Get OTP
                          </button>


                        </div>
                      </div>
                      {formik.touched.phone_number?.[0]?.number && formik.errors.phone_number?.[0]?.number ? (
                        <div className="text-danger rq-msg">{formik.errors.phone_number[0].number}</div>
                      ) : null}
                    </div>
                    {/* <div className='mb-3'>
                      <div className='d-flex align-items-center gap-4'>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="phoneNo_verify"
                            name='phone_number[0].phoneNo_verify'
                            value={formik.values.phoneNo_verify || isPhoneVerified}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={isPhoneVerified ? true : false}
                          />
                        </div>
                        <div>
                          <h6 className='semibold border-bottom border-light-subtle pb-2'>{kycData?.phone_number?.[0]?.[0]?.number || phone == undefined ? "" : `${phone}`}</h6>
                          <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>We will send you an Ident code to this mobile number via SMS during the identification.</p>
                        </div>
                      </div>
                    </div> */}
                    <h5 className='dash-head-1 mb-3'>What is your Current Address</h5>
                    <div className="mb-3">
                      <label htmlFor="address_1" className="form-label">Address Line 1</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Apt 203"
                        id="address_1"
                        name='current_Address[0].address_1'
                        value={formik.values.current_Address[0].address_1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      // onKeyPress={(e) => {
                      //   if (!/^[a-zA-Z0-9\s,.-]*$/.test(e.key)) {
                      //     e.preventDefault();
                      //   }
                      // }}
                      />
                      {formik.touched.current_Address?.[0]?.address_1 && formik.errors.current_Address?.[0]?.address_1 ? (
                        <div className="text-danger rq-msg">{formik.errors.current_Address[0].address_1}</div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="address_2" className="form-label">Address Line 2 <span className='dash-head'>(Optional)</span></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="5-4658 Barter Street"
                        id="address_2"
                        name='current_Address[0].address_2'
                        value={formik.values.current_Address[0].address_2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      // onKeyPress={(e) => {
                      //   if (!/^[a-zA-Z0-9\s,.-]*$/.test(e.key)) {
                      //     e.preventDefault();
                      //   }
                      // }}
                      />
                      {formik.touched.current_Address?.[0]?.address_2 && formik.errors.current_Address?.[0]?.address_2 ? (
                        <div className="text-danger rq-msg">{formik.errors.current_Address[0].address_2}</div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Resident Country
                      </label>
                      <Select
                        id="country"
                        name="current_Address[0].resident_country"
                        value={
                          formik.values.current_Address?.[0]?.resident_country
                            ? {
                              value: findCountryByName(formik.values.current_Address[0].resident_country)?.isoCode,
                              label: formik.values.current_Address[0].resident_country
                            }
                            : rescountry
                        }
                        options={Country.getAllCountries().map((country) => ({
                          value: country.isoCode,
                          label: country.name,
                        }))}
                        onChange={(selectedOption) => {
                          const country = Country.getCountryByCode(selectedOption.value);
                          setSelectedCountry(country);
                          formik.setFieldValue(
                            "current_Address[0].resident_country",
                            country ? country.name : ""
                          );
                        }}
                      />
                      {formik.touched.current_Address?.[0]?.resident_country &&
                        formik.errors.current_Address?.[0]?.resident_country && (
                          <div className="text-danger rq-msg">
                            {formik.errors.current_Address[0].resident_country}
                          </div>
                        )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="state" className="form-label">Provinces</label>
                      <Select
                        id="state"
                        value={
                          selectedCountry && formik.values.current_Address?.[0]?.state
                            ? {
                              value: findStateByName(formik.values.current_Address[0].state, selectedCountry.isoCode)?.isoCode,
                              label: formik.values.current_Address[0].state
                            }
                            : state
                        }
                        options={
                          selectedCountry
                            ? State.getStatesOfCountry(selectedCountry.isoCode).map((state) => ({
                              value: state.isoCode,
                              label: state.name,
                            }))
                            : []
                        }
                        onChange={(selectedOption) => {
                          const state = State.getStateByCodeAndCountry(selectedOption.value, selectedCountry?.isoCode);
                          setSelectedState(state);
                          formik.setFieldValue("current_Address[0].state", state ? state.name : "");
                        }}
                      />
                      {formik.touched.current_Address?.[0]?.state && formik.errors.current_Address?.[0]?.state && (
                        <div className="text-danger rq-msg">{formik.errors.current_Address[0].state}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">City</label>
                      <Select
                        id="city"
                        value={
                          selectedState && formik.values.current_Address?.[0]?.city
                            ? {
                              value: findCityByName(formik.values.current_Address[0].city, selectedCountry.isoCode, selectedState.isoCode)?.name,
                              label: formik.values.current_Address[0].city
                            }
                            : city
                        }
                        options={
                          selectedState
                            ? City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode).map((city) => ({
                              value: city.name,
                              label: city.name,
                            }))
                            : []
                        }
                        onChange={(selectedOption) => {
                          setCity({ value: selectedOption.value, label: selectedOption.label });
                          formik.setFieldValue("current_Address[0].city", selectedOption.label);
                        }}
                      />
                      {formik.touched.current_Address?.[0]?.city && formik.errors.current_Address?.[0]?.city && (
                        <div className="text-danger rq-msg">{formik.errors.current_Address[0].city}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="zip_code" className="form-label">Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="C1E 9Z9"
                        id="zip_code"
                        name='current_Address[0].zip_code'
                        value={formik.values.current_Address[0].zip_code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onKeyPress={(e) => {
                          const inputValue = e.target.value;
                          const isValid = /^[a-zA-Z0-9\s]*$/.test(e.key) && (e.key !== ' ' || inputValue.indexOf(' ') === -1);
                          if (!isValid) {
                            e.preventDefault();
                          }
                        }}

                      />
                      {formik.touched.current_Address?.[0]?.zip_code && formik.errors.current_Address?.[0]?.zip_code ? (
                        <div className="text-danger rq-msg">{formik.errors.current_Address[0].zip_code}</div>
                      ) : null}
                    </div>

                    <div className='mb-3'>
                      <div className='d-flex align-items-center gap-4'>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="document"
                            name='document'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <div>
                          <h6 className='semibold pb-2'>Your readily available identity document</h6>
                          <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>Your identity document will be checked during
                            the identification, so please have it ready and
                            at hand.</p>
                        </div>
                      </div>
                      {formik.touched.document && formik.errors.document ? (
                        <div className="text-danger rq-msg">{formik.errors.document}</div>
                      ) : null}
                    </div>
                    <div className='mb-3'>
                      <div className='d-flex align-items-center gap-4'>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="consent"
                            name='consent'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <div>
                          <h6 className='semibold pb-2'>Consent</h6>
                          <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>I agree to the Dnow terms of Service and
                            have read the Privacy Policy. I give my explicit
                            consent that.</p>
                        </div>
                      </div>
                      {formik.touched.consent && formik.errors.consent ? (
                        <div className="text-danger rq-msg">{formik.errors.consent}</div>
                      ) : null}
                    </div>

                    <p className='dash-trans-txt-2 pb-2'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>

                    {isLoading == true ? (
                      <button className="d-send-btn-1 rounded-pill" type="button">
                        <Spinner animation="border" />
                      </button>
                    ) : (
                      <div className='mb-5'>
                        <button type="submit" class="d-send-btn-1 rounded-pill"
                        // data-bs-toggle="modal" data-bs-target="#droptwo" 
                        >
                          Proceed
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Opt Modal --> */}
        <div class="modal fade" id="getopt" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="getoptLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <h1 class="modal-title fs-5" id="getoptLabel"></h1>
                <button type="button" id='otpclosebutton' class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body" >
                <div className='card dash-card-1'>
                  <div className='card-body py-5'>
                    <h5 className="dash-head-1  text-center">Enter Mobile OTP</h5>
                    <p className='dash-trans-txt-2 text-center mb-4'>An OTP has been sent your number <span className='dash-head'>{`+${phone}`}</span></p>
                    <div className='px-lg-4 profile-section-one '>
                      <OtpInput
                        numInputs={6}
                        id="phone_otp"
                        name='phone_otp'
                        value={verifyOtp}
                        onChange={setverifyOTP}
                        isInputNum={true}
                        renderSeparator={<span style={{ margin: '0 10px' }}> -</span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className={`form-control ${props.className} opt-box`}
                            style={{
                              textAlign: 'center',
                              flexGrow: 1,
                              width: 'auto',
                              minWidth: '50px',
                              height: '50px'
                            }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            // Prevent non-numeric input
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        )}
                        containerStyle={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          overflow: "scroll"
                        }}
                      />
                      <div className='text-end'>
                        {timer > 0 ? (
                          <span>Resend OTP in <span className='dash-head'>{formatTime(timer)}</span></span>
                        ) : (
                          <button type='button' className='dash-trans-txt-2 mt-1 bg-transparent border-0'
                            onClick={() => otpSubmit(false)} disabled={loading}
                          >
                            {loading ? 'Sending OTP...' : 'Resend OTP'}
                          </button>
                        )}
                      </div>
                      {/* <div className='text-end'>
                        <button type='button' className='dash-trans-txt-2 mt-1 bg-transparent border-0'
                          onClick={otpSubmit}
                        >
                          Resend OTP
                        </button>
                      </div> */}
                      {formik.touched.phone_number?.[0]?.phone_otp && formik.errors.phone_number?.[0]?.phone_otp ? (
                        <div className="text-danger rq-msg">{formik.errors.phone_number[0].phone_otp}</div>
                      ) : null}
                      <button className='d-send-btn-gr-1 mt-4' >
                        <div type="button" className='d-send-btn-gr-1-1' onClick={verifyMobile_otp}>
                          Submit
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0">
                {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Video-call Modal --> */}
        <div className="modal fade" id="videocall" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="videocallLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="videocallLabel"></h1>
                <button type="button" id="videoClosebuton" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <h5 className="dash-head-1 mb-3 text-center">It's Time to verify your Identity</h5>
                  <div className="d-flex align-items-center gap-4 justify-content-center p-2 mx-auto video-sec">
                    <div><img src={videocall} alt="correct" className="video-img" /></div>
                    <div className="dash-trans-txt-2">
                      <ul className='mb-0'>
                        <li>Keep your mobile phone handy.</li>
                        <li>A National Identity proof that bears your address.</li>
                      </ul>

                    </div>
                  </div>
                </div>
                <button className="d-flex justify-content-center d-send-btn-2 rounded-pill m-auto"
                  onClick={() => {
                    GenerateSDKLink();
                    // handleShowModal();
                  }}
                >Next</button>
              </div>
            </div>
          </div>
        </div>

        {/* next btn modal */}
        <Modal
          show={isModalVisible}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
          centered
          className="sumsub"
        >
          <Modal.Header closeButton>
            <Modal.Title>SDK Link Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLinkGenerated ? (
              <iframe
                src={sdkLink}
                title="SDK Link"
                width="100%"
                height="400px"
                style={{ border: 'none' }}
              />
            ) : (
              <p>The SDK link generation is in progress. Please wait.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={kycVerifyStatus}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#dropone">
          Number
        </button> */}

        {/* <!-- number Modal --> */}
        <div class="modal fade" id="dropone" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="droponeLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <h1 class="modal-title fs-5" id="droponeLabel"></h1>
                <button type="button" id='dropone1close' class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body border-0">
                <div className='mb-3'>
                  <div className='d-flex align-items-center gap-4'>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="phoneNo_verify"
                        name='phone_number[0].phoneNo_verify'
                        value={formik.values.phone_number?.[0]?.phoneNo_verify || false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      // checked={isPhoneVerified ? true : false}
                      />
                    </div>
                    <div>
                      {/* <h6 className='semibold border-bottom border-light-subtle pb-2'>{kycData?.phone_number?.[0]?.number || `+${phone}` || ""}</h6>
                      <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>We will send you an Ident code to this mobile number via SMS during the identification.</p> */}
                      <h6 className='semibold border-bottom border-light-subtle pb-2'>
                        Hi, <span className='dash-head'>{localStorage.getItem("First_name")}</span> we need to verify that <span className='dash-head'>{`+${phone}`}</span> is your's.
                      </h6>
                      <p>Why is this necessary: To comply with the KYC standards, You need to verify this phone number is registred under your name.</p>
                      <h6 className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>
                        <span className='dash-head'>{`+${phone}`}</span> is mine and I have it handy.
                      </h6>
                      <p style={{ color: "grey" }}>
                        Providing other phone number instead of <span className='dash-head'>{`+${phone}`}</span> during the KYC validation process will result to a failed account verification procees.
                      </p>
                    </div>
                  </div>
                  {formik.touched.phone_number?.[0]?.phoneNo_verify && formik.errors.phone_number?.[0]?.phoneNo_verify ? (
                    <div className="text-danger rq-msg">{formik.errors.phone_number?.[0]?.phoneNo_verify}</div>
                  ) : null}
                </div>
              </div>
              <div class="modal-footer border-0 justify-content-center">
                <button type="button" class="d-send-btn-1 rounded-pill w-75"
                  //data-bs-dismiss="modal"
                  onClick={() => otpSubmit(true)}
                  disabled={isPhoneVerified == true ? true : false}
                >Proceed</button>
              </div>
            </div>
          </div>
        </div>



        {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#droptwo">
          Proceed
        </button> */}

        {/* <!-- proceed Modal --> */}
        {/* <div class="modal fade" id="droptwo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="droptwoLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <h1 class="modal-title fs-5" id="droptwoLabel"></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body border-0">
                <div className='mb-3'>
                  <div className='d-flex align-items-center gap-4'>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="document"
                        name='document'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div>
                      <h6 className='semibold pb-2'>Your readily available identity document</h6>
                      <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>Your identity document will be checked during
                        the identification, so please have it ready and
                        at hand.</p>
                    </div>
                  </div>
                  {formik.touched.document && formik.errors.document ? (
                    <div className="text-danger rq-msg">{formik.errors.document}</div>
                  ) : null}
                </div>
                <div className='mb-3'>
                  <div className='d-flex align-items-center gap-4'>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="consent"
                        name='consent'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div>
                      <h6 className='semibold pb-2'>Consent</h6>
                      <p className='dash-trans-txt-2  border-bottom border-light-subtle pb-2'>I agree to the Dnow terms of Service and
                        have read the Privacy Policy. I give my explicit
                        consent that.</p>
                    </div>
                  </div>
                  {formik.touched.consent && formik.errors.consent ? (
                    <div className="text-danger rq-msg">{formik.errors.consent}</div>
                  ) : null}
                </div>
              </div>
              <div class="modal-footer border-0 justify-content-center">
                <button type="button" class="d-send-btn-1 rounded-pill w-75" data-bs-dismiss="modal">Proceed</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Accountsection