import binance from "../assets/images/Crypto/binance.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IoIosCopy } from "react-icons/io";
import usdc from "../assets/images/Crypto/CoinUSDC.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdSwapVert } from "react-icons/md";
import eth from "../assets/images/Crypto/eth.png";
import 'react-calendar/dist/Calendar.css';
import React, { useEffect, useState, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import Success from '../assets/images/dashboard/success.gif';
import { CiReceipt } from "react-icons/ci";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSocket } from "../config/socketContext";
import { toastify } from "../helper/common";
import { makeApiRequest } from "../config/AxiosService"

function Cryptoinner() {
    const socket = useSocket();
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const { pairName } = params;

    const [date, setDate] = useState(new Date());
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [range, setRange] = useState([new Date()]);
    const [cryptoList, setCryptoList] = useState(location.state?.coinData);

    const [selectedSymbol, setSelectedSymbol] = useState(cryptoList?.symbol);
    const [overallData, setOverallData] = useState({});
    const [orderBookList, setOrderBookList] = useState({
        bids: [],
        asks: [],
    });
    const [userId, setUserId] = useState();

    const [orderData, setOrderData] = useState({
        price: "",
        amount: "",
        total: "",
        type: "buy",
        orderType: "limit",
    });

    // const [activeTab, setActiveTab] = useState("buy");
    // console.log('orderData', orderData)
    // const [orderType, setOrderType] = useState("limit");
    // const [price, setPrice] = useState("");
    // const [amount, setAmount] = useState("");
    // const [total, setTotal] = useState("");
    // const [pair, setPair] = useState(null);
    // const [livePrice, setLivePrice] = useState(null);
    // const pair = cryptoList?.symbol

    // const handleTabChange = (tab) => {
    //     setActiveTab(tab);
    // };

    // useEffect(() => {
    //     socket.on('LiveDataPrice', (data) => {
    //         console.log('data', data)
    //       setLivePrice(data.current_price);
    //     });

    //     return () => socket.disconnect();
    //   }, []);

    // const handlePriceChange = (e) => {
    //     setPrice(e.target.value);
    //     if (amount) {
    //         setPrice(e.target.value);
    //         calculateTotal(e.target.value, amount);

    //     }
    // };

    // const handleAmountChange = (e) => {
    //     setAmount(e.target.value);
    //     if (price) {
    //         setAmount(e.target.value);
    //         calculateTotal(price, e.target.value);
    //     }
    // };

    // const calculateTotal = (price, amount) => {
    //     if (price && amount) {
    //         setTotal((parseFloat(price) * parseFloat(amount)).toFixed(2));
    //     } else {
    //         setTotal("");
    //     }
    // };

    // const handleOrderSubmit = () => {
    //     const orderData = {
    //         type: activeTab, // buy or sell
    //         orderType,
    //         pairName,
    //         price: orderType === "market" ? null : parseFloat(price),
    //         amount: parseFloat(amount),
    //         // userId
    //     };
    //     socket.emit("createOrder", orderData);
    // };

    useEffect(() => {
        const getUserData = async () => {
            try {
                let params = {
                    url: "user-userData",
                    method: "GET"
                }
                const resp = await makeApiRequest(params)
                console.log('resp--', resp)
                console.log('resp--', resp.resp._id)
                const userID = resp.resp._id
                setUserId(userID)
            } catch (error) {

            }

        }
        getUserData();
    }, [])



    const calculateTotal = (price, amount) => {
        if (price && amount) {
            return (parseFloat(price) * parseFloat(amount)).toFixed(2);
        }
        return '';
    };

    const handleTabChange = (tab) => {
        setOrderData((prev) => ({ ...prev, type: tab }));
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrderData((prev) => {
            const newOrderData = { ...prev, [name]: value };
            if ((name === 'price' || name === 'amount') && newOrderData.price && newOrderData.amount) {
                newOrderData.total = calculateTotal(newOrderData.price, newOrderData.amount);
            }
            return newOrderData;
        });
    };

    const handleOrderSubmit = (e) => {
        e.preventDefault();
        const { price, amount, type, orderType } = orderData;
        const order = {
            userId,
            type,
            orderType,
            pair:pairName,
            price: orderType === 'market' ? null : parseFloat(price),
            amount: parseFloat(amount),

        };
        socket.emit('createTrade', order);
    };

    // console.log('orderBookList', orderBookList)
    useEffect(() => {
        socket.on("LiveDataPrice", (data) => {
            let updatedData = {};
            data.forEach((cryptoDatas) => {
                if (cryptoDatas?.symbol == selectedSymbol) {
                    setCryptoList(cryptoDatas);

                }
                updatedData[cryptoDatas?.symbol] = cryptoDatas;
            });
            setOverallData(updatedData);
        });

        return () => {
            socket.off("LiveDataPrice");
        };
    }, [socket, selectedSymbol]);


    useEffect(() => {
        if (socket) {
            socket.on("orderBookUpdate", (orderData) => {

                if (orderData?.symbol?.toLowerCase() === selectedSymbol?.toLowerCase()) {
                    setOrderBookList({
                        bids: orderData.bids || [],
                        asks: orderData.asks || [],
                    });
                }
                // console.log('orderData', orderData,selectedSymbol)
            });

            return () => {
                socket.off("orderBookUpdate");
            };
        }
    }, [socket, selectedSymbol]);



    const onLoadScriptRef = useRef();
    let tvScriptLoadingPromise;

    const handleSelect = (event) => {
        setSelectedSymbol(event.target.value);
        // console.log("handleSelect", event.target);

        // navigate(`/trade/${data?.symbol}`)
    };
    const toggleCalendar = () => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const datas = [
        {
            id: 1,
            time: "2022-07-22 11:20:00",
            symbol: "Perpetual",
            side: "Sell",
            price: "258.22",
            quantity: "256.32USDT",
            fee: "0.011155455 USDT",
            realizedprofit: "14.22566665548 USDT",
        },
        {
            id: 2,
            time: "2022-07-22 11:20:00",
            symbol: "Perpetual",
            side: "Sell",
            price: "258.22",
            quantity: "256.32USDT",
            fee: "0.011155455 USDT",
            realizedprofit: "14.22566665548 USDT",
        },
        {
            id: 3,
            time: "2022-07-22 11:20:00",
            symbol: "Perpetual",
            side: "Sell",
            price: "258.22",
            quantity: "256.32USDT",
            fee: "0.011155455 USDT",
            realizedprofit: "14.22566665548 USDT",
        },
        {
            id: 4,
            time: "2022-07-22 11:20:00",
            symbol: "Perpetual",
            side: "Sell",
            price: "258.22",
            quantity: "256.32USDT",
            fee: "0.011155455 USDT",
            realizedprofit: "14.22566665548 USDT",
        }


    ];

    // chart

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }
            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());
            return () => onLoadScriptRef.current = null;
            function createWidget() {
                if (document.getElementById('tradingview_bc691') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        width: "100%",
                        height: 530,
                        autosize: true,
                        symbol: `BINANCE:${pairName}`,

                        interval: "D",
                        timezone: "Etc/UTC",
                        //   theme: theme == 'dark-new' ? "Dark" : "Light",
                        locale: "en",
                        toolbar_bg: "#010215",
                        //   backgroundColor:theme == 'dark-new' ? "#010215" : "#ffffff" , 
                        //   gridColor: theme == 'dark-new' ? "#010215" : "#ffffff", 
                        hide_volume: false,
                        hide_top_toolbar: false,
                        withdateranges: false,
                        hide_legend: false,
                        allow_symbol_change: false,
                        enablezoom: true,
                        showMA: false,
                        hide_side_toolbar: false,
                        container_id: "tradingview_bc691",
                        overrides: {
                            'mainSeriesProperties.areaStyle.color2': '#010215',
                            'mainSeriesProperties.areaStyle.color1': '#010215',
                        },
                    });
                }
            }
        },
        [pairName]
    );

    useEffect(() => {
        if (socket !== null) {
            socket.on("tradeError", async (errorMsg) => {
                console.log('errorMsg', errorMsg)
                toastify("error", errorMsg.msg)
            });
            return () => {
                socket.off("tradeError");
            };
        }
    }, [socket])

    return (
        <div className='cryptoinner-section'>

            <div className='cryptoinner-section-one'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='graph-section card dash-card-1 h-100'>
                                <div class="card-body">
                                    <div className='d-flex align-items-center justify-content-between py-2 flex-lg-row flex-wrap gap-3'>
                                        <div className='d-flex align-items-center gap-1'>
                                            <LazyLoadImage alt="binance" src={binance} className='' />
                                            <div className="dropdown">
                                                <select
                                                    className="form-select"
                                                    id="dropdownMenuButton"
                                                    aria-label="Select cryptocurrency"
                                                    // value={pairName.split("USDT").join("/USDT")}
                                                    value={pairName}
                                                    onChange={(e) => {
                                                        const selectedSymbol = e.target.value;
                                                        navigate(`/trade/${selectedSymbol}`);
                                                        handleSelect(e);
                                                    }}
                                                    name="pairname"

                                                >
                                                    {Object.entries(overallData).map(([key, data]) => (
                                                        <option value={data?.symbol} key={key}>
                                                            {data?.symbol?.substring(0, 3) + "/" + data?.symbol?.substring(3, 7)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-three fc-b">
                                                {cryptoList?.current_price}
                                            </div>
                                            <div className="text-four">Price</div>
                                        </div>
                                        <div>
                                            <div className="text-three fc-b">
                                                {cryptoList?.change_24h}
                                            </div>
                                            <div className="text-four">24h changes</div>
                                        </div>
                                        <div>
                                            <div className="text-three fc-b">
                                                {cryptoList?.highest_price}
                                            </div>
                                            <div className="text-four">24h high</div>
                                        </div>
                                        <div>
                                            <div className="text-three fc-b">
                                                {cryptoList?.lowest_price}
                                            </div>
                                            <div className="text-four">24h low</div>
                                        </div>
                                        <div>
                                            <div className="text-three fc-b">
                                                {cryptoList?.volume}
                                            </div>
                                            <div className="text-four">24h volume ({cryptoList?.symbol?.substring(0, 3)})</div>
                                        </div>

                                    </div>

                                    <div className='child-graph widget-container'>
                                        <div className='tradingview-widget-container' style={{ height: "85vh", width: "100%", borderRadius: "20px" }}>
                                            <div id='tradingview_bc691' style={{ height: "calc(100% - 32px)", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">

                            <div className="cryto-table card dash-card-1">
                                <div class="card-body">

                                    <div className="trading-page-section">
                                        <h6>Orderbook</h6>
                                        <div className="progress-bar-bg-color">
                                            <div className="d-flex flex-row mb-1 mobile-justify-content orderbook-table-heading-section border-top-0">
                                                <div className="progress-table-1">
                                                    <span className="orderbook-text-1">
                                                        Price(USDT)
                                                    </span>
                                                </div>
                                                <div className="progress-table-2">
                                                    <span className="orderbook-text-1">
                                                        Amount(ETH)
                                                    </span>
                                                </div>
                                                <div className="progress-table-3">
                                                    <span className="orderbook-text-1">
                                                        Total(USDT)
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="trading-table-left-section">
                                                <ul className="list-unstyled">
                                                    {orderBookList.bids.map(([price, quantity, total], index) => (
                                                        <li className="">
                                                            <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                                <div class="progress-table-1">
                                                                    <span class="orderbook-text-2 text-danger">{parseFloat(price)}</span>
                                                                </div>
                                                                <div class="progress-table-2">
                                                                    <span class="orderbook-text-2"> {parseFloat(quantity)}</span>
                                                                </div>
                                                                <div class="progress-table-3">
                                                                    <span class="orderbook-text-2">{parseFloat(total).toFixed(4)}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                        <div class="d-flex flex-row orderbook-table-heading-section gap-1 py-2">
                                            <div class="">
                                                <span class="trade-text-5 text-success">3205.51</span>
                                            </div>
                                            <div class="orderBook-usd-price">
                                                <div class="ps-2"><span class="orderbook-text-2-12">$3204.37</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trading-table-left-section progress-bar-bg-color ">
                                            <ul className="list-unstyled">
                                                {orderBookList.asks.map(([price, quantity, total], index) => (
                                                    <li className="">
                                                        <div className="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-success">{parseFloat(price)}</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">{parseFloat(quantity)}</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">{parseFloat(total).toFixed(4)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cryto-table card dash-card-1 my-1">
                                <div class="card-body">
                                    <div className="trading-page-section">
                                        <h6>Recent Trades</h6>
                                        <div className="progress-bar-bg-color">
                                            <div className="d-flex flex-row mb-1 mobile-justify-content orderbook-table-heading-section border-top-0">
                                                <div className="progress-table-1">
                                                    <span className="orderbook-text-1">
                                                        Price(USDT)
                                                    </span>
                                                </div>
                                                <div className="progress-table-2">
                                                    <span className="orderbook-text-1">
                                                        Amount(ETH)
                                                    </span>
                                                </div>
                                                <div className="progress-table-3">
                                                    <span className="orderbook-text-1">
                                                        UTC-Time
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="trading-table-left-section">
                                                <ul className="list-unstyled">
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-success">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-danger">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-success">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-danger">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-success">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-danger">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-success">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-danger">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-success">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="">
                                                        <div class="d-flex flex-row mobile-justify-content  cursor-poniter">
                                                            <div class="progress-table-1">
                                                                <span class="orderbook-text-2 text-danger">3399.56000</span>
                                                            </div>
                                                            <div class="progress-table-2">
                                                                <span class="orderbook-text-2">6798.50000000</span>
                                                            </div>
                                                            <div class="progress-table-3">
                                                                <span class="orderbook-text-2">00:00:00</span>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="cryto-table card dash-card-1">
                                <div className="card-body">
                                    <div className="" style={{ height: '50px' }}>
                                        <table className="trading-table overflow-auto">
                                            <thead className="">
                                                <tr>
                                                    <th>Price(USDT)</th>
                                                    <th>Amount(ETH)</th>
                                                    <th>Total(USDT)</th>
                                                </tr>
                                            </thead>

                                            <tbody className="">
                                                <tr>
                                                    <td className="text-danger">3221.30</td>
                                                    <td>0.0009</td>
                                                    <td>2.3991</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-danger">3221.30</td>
                                                    <td>0.0009</td>
                                                    <td>2.3991</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="" style={{ height: '50px' }}>
                                        <table className="trading-table overflow-auto">
                                            <tbody>
                                                <tr>
                                                    <td className="text-danger fs-6 fw-semibold">3221.30 <span>$3213.11</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="" style={{ height: '50px' }}>
                                        <table className="trading-table overflow-auto">
                                            <thead className="">
                                                <tr>
                                                    <th>Price(USDT)</th>
                                                    <th>Amount(ETH)</th>
                                                    <th>Total(USDT)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-danger">3221.30</td>
                                                    <td>0.0009</td>
                                                    <td>2.3991</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>

                    <div className='row py-2'>
                        <div className="col">
                            <div className="swap-section card dash-card-1">
                                <div class="card-body">
                                    <h5 className="pb-1">Spot</h5>
                                    <ul class="nav nav-pills mb-3 nav-justified gap-lg-2 gap-1" id="pills-tab" role="tablist">
                                        <li class="nav-item flex-grow-0" role="presentation">
                                            <button
                                                className="nav-link active"
                                                id="pills-buy-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-buy"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-buy"
                                                aria-selected="true"
                                                onClick={() => handleTabChange('buy')}
                                            >Buy</button>
                                        </li>
                                        <li class="nav-item flex-grow-0" role="presentation">
                                            <button
                                                className="nav-link"
                                                id="pills-sell-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-sell"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-sell"
                                                aria-selected="false"
                                                onClick={() => handleTabChange('sell')}
                                            >Sell</button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab" tabindex="0">
                                            <div className="d-flex align-items-center py-2 border-bottom-css">
                                                <div className='d-flex align-items-center gap-1'>
                                                    <LazyLoadImage alt="binance" src={binance} className='' />
                                                    <div><div className="text-one">BTC/USDT</div><div className="text-two">Binance</div></div>
                                                </div>
                                                <div className="ms-auto text-end"><div><div className="text-one fw-bold">0xa010...35B9e1</div><div className="text-two">Metamask wallet</div></div></div>
                                            </div>

                                            <div className="pt-2">
                                                <div className='row align-items-lg-end align-items-center'>
                                                    <div className="col-lg-6 flex-grow-1 py-2">
                                                        <select
                                                            class="form-select form-control"
                                                            aria-label="Default select example"
                                                            value={orderData.orderType}
                                                            onChange={handleChange}
                                                        >
                                                            <option selected>Limit</option>
                                                            <option value="1">Market</option>
                                                            {/* <option value="2">Stop Limit</option> */}
                                                        </select>
                                                    </div>
                                                    {orderData.orderType === "limit" && (
                                                        <div className="col-lg-6 flex-grow-1 py-2">
                                                            <label for="from1">Price (USDT)</label>
                                                            <input
                                                                type="text"
                                                                className="form-control custom-placeholder-input"
                                                                id="from1"
                                                                placeholder=""
                                                                value={orderData.price}
                                                                name="price"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='row align-items-center'>
                                                    <div className="col-lg-6 flex-grow-1 py-2">
                                                        <label for="from2">Amount (ETH)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-placeholder-input"
                                                            id="from2"
                                                            placeholder=""
                                                            name="amount"
                                                            value={orderData.amount}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 flex-grow-1 py-2">
                                                        <label for="from3">Total (USDT)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-placeholder-input"
                                                            id="from3"
                                                            placeholder="Total"
                                                            value={orderData.total}
                                                        />
                                                    </div>
                                                </div>



                                            </div>

                                            <div className="py-3">
                                                <div className="d-flex align-items-center py-1">
                                                    <div className="text-three">Price per USDC</div>
                                                    <div className="ms-auto text-end text-three fw-semibold">0.035422 ETH</div>
                                                </div>
                                                <div className="d-flex align-items-center py-1">
                                                    <div className="text-three">Price impact</div>
                                                    <div className="ms-auto text-end text-three fw-semibold fc-g">0.24 %</div>
                                                </div>
                                            </div>

                                            <div className="pt-2">
                                                <div className="text-center">
                                                    <button class="done-css" data-bs-toggle="modal" data-bs-target="#buymodal" onClick={handleOrderSubmit}>{orderData.type === 'buy' ? 'Buy' : 'Sell'}</button>
                                                </div>
                                                {/* <!-- Button trigger modal --> */}
                                                {/* <!-- Modal --> */}
                                                <div class="modal fade buy-modal" id="buymodal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="buymodalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered">
                                                        <div class="modal-content">
                                                            <div class="modal-body text-center py-4 modal-body-circle">
                                                                <LazyLoadImage alt="Prof" src={Success} width={85} />
                                                                <h1 style={{ color: "var(--color-1)" }}>0,0023 BTC</h1>
                                                                <h5 style={{ color: "var(--color-1)" }}></h5>
                                                                <h3 style={{ marginBottom: "100px" }}>$ 50.00</h3>

                                                                <p className='text-muted mb-4'>You have successfully purchased crypto asset, all of your assets will be shown on Wallet menu under your My Assets</p>
                                                                <div className='mb-5'>
                                                                    <button className='d-send-btn-1'>Done</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="pills-sell" role="tabpanel" aria-labelledby="pills-sell-tab" tabindex="0">
                                            <div className="d-flex align-items-center py-2 border-bottom-css">
                                                <div className='d-flex align-items-center gap-1'>
                                                    <LazyLoadImage alt="binance" src={binance} className='' />
                                                    <div><div className="text-one">BTC/USDT</div><div className="text-two">Binance</div></div>
                                                </div>
                                                <div className="ms-auto text-end"><div><div className="text-one fw-bold">0xa010...35B9e1</div><div className="text-two">Metamask wallet</div></div></div>
                                            </div>

                                            <div className="pt-2">
                                                <div className='row align-items-lg-end align-items-center'>
                                                    <div className="col-lg-6 flex-grow-1 py-2">
                                                        <select class="form-select form-control"
                                                            aria-label="Default select example"
                                                            value={orderData.orderType}
                                                            onChange={handleChange}
                                                        >
                                                            <option selected>Limit</option>
                                                            <option value="1">Market</option>
                                                            {/* <option value="2">Stop Limit</option> */}
                                                        </select>
                                                    </div>
                                                    {/* <h5>Live Price: {livePrice ? `$${livePrice}` : 'Loading...'}</h5> */}
                                                    {orderData.orderType === "limit" && (
                                                        <div className="col-lg-6 flex-grow-1 py-2">
                                                            <label for="from4">Price (USDT)</label>
                                                            <input
                                                                type="text"
                                                                className="form-control custom-placeholder-input"
                                                                id="from4"
                                                                placeholder="Enter price"
                                                                value={orderData.price}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='row align-items-center'>
                                                    <div className="col-lg-6 flex-grow-1 py-2">
                                                        <label for="from5">Amount (ETH)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-placeholder-input"
                                                            id="from5"
                                                            placeholder=""
                                                            value={orderData.amount}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 flex-grow-1 py-2">
                                                        <label for="from6">Total (USDT)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-placeholder-input"
                                                            id="from6"
                                                            placeholder="Total"
                                                            value={orderData.total}
                                                        />
                                                    </div>
                                                </div>



                                            </div>

                                            <div className="py-3">
                                                <div className="d-flex align-items-center py-1">
                                                    <div className="text-three">Price per USDC</div>
                                                    <div className="ms-auto text-end text-three fw-semibold">0.035422 ETH</div>
                                                </div>
                                                <div className="d-flex align-items-center py-1">
                                                    <div className="text-three">Price impact</div>
                                                    <div className="ms-auto text-end text-three fw-semibold fc-g">0.24 %</div>
                                                </div>
                                            </div>

                                            <div className="pt-2">
                                                <div className="text-center">
                                                    <button class="done-css" data-bs-toggle="modal" data-bs-target="#sellmodal" onClick={handleOrderSubmit} >{orderData.type === 'buy' ? 'Buy' : 'Sell'}</button>
                                                </div>

                                                <div class="modal fade buy-modal" id="sellmodal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="sellmodalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-centered">
                                                        <div class="modal-content">
                                                            <div class="modal-body text-center py-4 modal-body-circle">
                                                                <LazyLoadImage alt="Prof" src={Success} width={85} />
                                                                <h1 style={{ color: "var(--color-1)" }}>0,0023 BTC</h1>
                                                                <h5 style={{ color: "var(--color-1)" }}></h5>
                                                                <h3 style={{ marginBottom: "100px" }}>$ 50.00</h3>
                                                                {/* <a><h5 style={{ color: "var(--color-1)" }} className='mb-5'><CiReceipt /> View Receipt</h5></a> */}
                                                                <p className='text-muted mb-4'>You have successfully purchased crypto asset, all of your assets will be shown on Wallet menu under your My Assets</p>
                                                                <div className='mb-5'>
                                                                    <button className='d-send-btn-1'>Done</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row pt-3 pb-5 trade-section">
                        <div className="col-lg-10 col-12">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item border-end border-secondary-subtle" role="presentation">
                                    <button class="nav-link active" id="pills-tradehistory-tab" data-bs-toggle="pill" data-bs-target="#pills-tradehistory" type="button" role="tab" aria-controls="pills-tradehistory" aria-selected="true">Trade History</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-mytrade-tab" data-bs-toggle="pill" data-bs-target="#pills-mytrade" type="button" role="tab" aria-controls="pills-mytrade" aria-selected="false">My Trade</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-tradehistory" role="tabpanel" aria-labelledby="pills-tradehistory-tab" tabindex="0">
                                    <div className="cryto-table card dash-card-1">
                                        <div class="card-body">
                                            <div className="crypto-table-options">
                                                <div className="option-btn">1 day</div>
                                                <div className="option-btn">1 week</div>
                                                <div className="option-btn">1 month</div>
                                                <div className="option-btn">3 month</div>
                                                <form className="d-flex align-items-center"><input type="text" className="search-input" placeholder="YYYY-MM-DD-YYYY-MM-DD" /><button type="submit" className="search-btn">Search</button></form>
                                            </div>
                                            <table className="cryto-table-section border-0">

                                                <thead>
                                                    <tr>
                                                        <th>Time</th>
                                                        <th>Symbol</th>
                                                        <th>Side</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Fee</th>
                                                        <th>Realized Profit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {datas.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{item.time}</td>
                                                            <td><span className="fw-semibold me-2">BNBUSD</span>{item.symbol}</td>
                                                            <td className="fc-r fw-bold">{item.side}</td>
                                                            <td>{item.price}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.fee}</td>
                                                            <td>{item.realizedprofit}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-mytrade" role="tabpanel" aria-labelledby="pills-mytrade-tab" tabindex="0">
                                    <div className="cryto-table card dash-card-1">
                                        <div class="card-body">
                                            <div className="crypto-table-options">
                                                <div className="option-btn">1 day</div>
                                                <div className="option-btn">1 week</div>
                                                <div className="option-btn">1 month</div>
                                                <div className="option-btn">3 month</div>
                                                <form className="d-flex align-items-center"><input type="text" className="search-input" placeholder="YYYY-MM-DD-YYYY-MM-DD" /><button type="submit" className="search-btn">Search</button></form>
                                            </div>
                                            <table className="cryto-table-section border-0">

                                                <thead>
                                                    <tr>
                                                        <th>Time</th>
                                                        <th>Symbol</th>
                                                        <th>Side</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Fee</th>
                                                        <th>Realized Profit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {datas.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{item.time}</td>
                                                            <td><span className="fw-semibold me-2">BNBUSD</span>{item.symbol}</td>
                                                            <td className="fc-r fw-bold">{item.side}</td>
                                                            <td>{item.price}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.fee}</td>
                                                            <td>{item.realizedprofit}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            1
                                        </div>
                                        <div className="col-lg-12">
                                            2
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    3
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </div >
    )

}
export default Cryptoinner