let config = {};
let environment = "live";
if (environment == "local") {
  config = {
    frontendurl: "http://localhost:3000/",
    backendurl: "http://localhost:5000/",
    btcRegex: /^(tb1|[mn2])[a-zA-HJ-NP-Z0-9]{25,39}$/,
    ltcRegex: /^(m|n)[a-km-zA-HJ-NP-Z1-9]{33}|Q[a-km-zA-HJ-NP-Z1-9]{33}|tltc1[a-z0-9]{39,59}$/,
    adaRegex: /^((addr_test1)[a-z0-9]{53}|(2cWKM)[a-zA-Z0-9]{98})$/
  };
} else {
  config = {
    frontendurl: "https://staging.rempic.com/",
    backendurl: "https://api.rempic.com/",
    btcRegex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/,
    ltcRegex: /^(L[a-km-zA-HJ-NP-Z1-9]{33}|M[a-km-zA-HJ-NP-Z1-9]{33}|ltc1[a-z0-9]{39,59})$/,
    adaRegex: /^((addr1)[a-z0-9]{58}|(DdzFF)[a-zA-Z0-9]{93})$/
  };
}
export default config;