import { toast } from "react-toastify";

export const toastify = (type, message) => {
    if (type == "error") {
        toast.error(message, {
            autoClose: 2000,
            position: "top-right"
        });
    } else if (type === "success") {
        toast.success(message, {
            autoClose: 2000,
            position: "top-right"
        });
    } else {
        toast.warn(message, {
            autoClose: 2000,
            position: "top-right"
        });
    };
};