import React, { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2';
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from "react-toastify";
import { makeApiRequest } from "../../config/AxiosService"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { jwtDecode } from 'jwt-decode';
import Privacypolicy from './Privacypolicy';
import { Spinner } from 'react-bootstrap';

function Signup() {
    const [data, setData] = useState({})
    const [userEmail, setUserEmail] = useState("")
    const [RegisterToken, setRegisterToken] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isSignedUp, setIsSignedUp] = useState(false);
    const [verifyOTP, setverifyOTP] = useState('');
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const Navigate = useNavigate();

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const startTokenTimer = (token) => {
        const decodedToken = jwtDecode(token);

        const currentTime = Math.floor(Date.now() / 1000);
        const timeToExpiry = decodedToken.exp - currentTime;
        if (timeToExpiry > 0) {
            setTimer(timeToExpiry);
        } else {
            handleTokenExpiry();
        }
    };

    const handleTokenExpiry = () => {
        toast.error("Session expired. Please sign in again.");
        setIsSignedUp(false);
        setRegisterToken(null);
    };

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const regex = /^[a-zA-Z0-9#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9#$%&'*+\-/=?^_`{|}~]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
    const validationSchema = Yup.object({
        first_name: Yup.string().required('First name is required!').matches(/^[A-Za-z. ]*$/, "Enter a Valid Name"),
        last_name: Yup.string().required('Last name is required!').matches(/^[A-Za-z. ]*$/, "Enter a Valid Name"),
        email: Yup.string()
            .required('Email is required!')
            .matches(regex, 'invalid email'),
        password: Yup.string()
            .required('Password is required!')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#]).{8,}$/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Please confirm your password!'),
        termsAndConditions: Yup.bool().oneOf([true], 'You must accept the terms and conditions!'),
    });
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirmPassword: '',
            referral_id: "",
            termsAndConditions: false,
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                setIsLoading(true)
                const payload = {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email.toLowerCase(),
                    password: values.password,
                    referral_id: values.referral_id,
                };
                let params = {
                    url: `user-signup`,
                    method: "POST",
                    data: payload,
                };
                setUserEmail(values.email)
                setData(payload)
                const response = await makeApiRequest(params);

                if (response.status) {
                    setIsLoading(false)
                    toast.success(response.message);
                    setRegisterToken(response.token);
                    startTokenTimer(response.token);
                    setTimeout(() => {
                        setIsSignedUp(true);
                    }, 2000);
                } else {
                    toast.error(response.message);
                    setIsLoading(false)
                    setIsSignedUp(false);
                }
            } catch (error) {
                toast.error(error.message);
                setIsLoading(false)
                setIsSignedUp(false);
            }
        },
    });

    const handleOtpSubmit = async () => {
        if (!RegisterToken) {
            toast.error("Session expired. Please sign in again.");
            return;
        }
        try {
            const params = {
                url: `user-registerOTP`,
                method: "POST",
                data: { RegisterToken, verifyOTP },
            };
            const response = await makeApiRequest(params);

            if (response.status) {
                toast.success(response.message);
                setTimeout(() => {
                    Navigate("/signin");
                }, 2500);
            } else if (response.message === "OTP is Expired") {
                setverifyOTP("")
                toast.error("OTP has expired. Please request a new one.");
                setRegisterToken(null);
            } else {
                setverifyOTP("")
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Error verifying OTP.");
        }
    };

    const handleResendOTP = async () => {
        setLoading(true);
        try {
            const params = {
                url: 'user-resendOTP',
                method: 'POST',
                data: { data },
            };
            const response = await makeApiRequest(params);

            if (response.status == true) {
                toast.success(response.message);
                setRegisterToken(response.token);
                startTokenTimer(response.token);
                setverifyOTP("")
            } else {
                setverifyOTP("")
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Error resending OTP. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timer]);

    return (
        <div className='bef-login-home-sec'>
            {/* <ToastContainer /> */}
            <section className='bef-signin-h-sec py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            {!isSignedUp ? (
                                <div className='card dash-card-1'>
                                    <div className='card-body'>
                                        <h5 className="dash-head-1 mb-2">Create an Account</h5>
                                        <p className="mb-0 dash-trans-txt-2 mb-4">Get an account and control your finances better with full control of your budgets and savings.</p>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className='profile-section-one'>
                                                <div className="mb-3">
                                                    <label htmlFor="first_name" className="form-label">First Name</label>
                                                    <input
                                                        type="text"
                                                        name="first_name"
                                                        id="first_name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.first_name}
                                                        className="form-control"
                                                        placeholder="First Name"
                                                    />
                                                    {formik.touched.first_name && formik.errors.first_name && <div className="text-danger rq-msg">{formik.errors.first_name}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="last_name" className="form-label">Last Name</label>
                                                    <input
                                                        type="text"
                                                        name="last_name"
                                                        id="last_name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.last_name}
                                                        className="form-control"
                                                        placeholder="Last Name" />
                                                    {formik.touched.last_name && formik.errors.last_name && <div className="text-danger rq-msg">{formik.errors.last_name}</div>}
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email}
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="john@email.com"
                                                    />
                                                    {formik.touched.email && formik.errors.email && <div className="text-danger rq-msg">{formik.errors.email}</div>}
                                                </div>

                                                <div className="position-relative mb-3">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <div className='input-group'>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            name="password"
                                                            className="form-control password-input"
                                                            id="password"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.password}
                                                            autocomplete="new-password"
                                                            placeholder="**********"
                                                            style={{ borderRadius: "0.375rem" }}
                                                        />

                                                        <span
                                                            className="password-toggle-icon"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                        </span>
                                                    </div>
                                                    {formik.touched.password && formik.errors.password && <div className="text-danger rq-msg">{formik.errors.password}</div>}
                                                </div>

                                                <div className="position-relative mb-3">
                                                    <label htmlFor="confirmPassword" className="form-label">Re-Enter Password</label>
                                                    <div className='input-group'>
                                                        <input
                                                            type={showConfirmPassword ? 'text' : 'password'}
                                                            className="form-control password-input"
                                                            name="confirmPassword"
                                                            id="confirmPassword"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.confirmPassword}
                                                            autocomplete="new-password"
                                                            placeholder="**********"
                                                            style={{ borderRadius: "0.375rem" }}
                                                        />
                                                        <span
                                                            className="password-toggle-icon"
                                                            onClick={toggleConfirmPasswordVisibility}
                                                        >
                                                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                                        </span>
                                                    </div>
                                                    {formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="text-danger rq-msg">{formik.errors.confirmPassword}</div>}
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="referral_id" className="form-label">Referral ID <span className='dash-head'>(Optional)</span></label>
                                                    <input
                                                        type="text"
                                                        name="referral_id"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.referral_id}
                                                        className="form-control"
                                                        id="referral_id"
                                                        placeholder="referral ID12345" />
                                                </div>

                                                <div className="mb-3 form-check align-items-center gap-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="exampleCheck1"
                                                        name="termsAndConditions"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.termsAndConditions}
                                                    />

                                                    <div className="form-label mb-0" htmlFor="exampleCheck1">
                                                        <span className='opacity-50'>I have read and agree to </span>
                                                        <button type="button" class="bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#termsandconditions">
                                                            Terms and Conditions
                                                        </button>
                                                        <span className='opacity-50'> & </span>
                                                        <button type="button" class="bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#privacy">
                                                            Privacy policy
                                                        </button>
                                                    </div>
                                                    {formik.touched.termsAndConditions && formik.errors.termsAndConditions && <div className="text-danger rq-msg">{formik.errors.termsAndConditions}</div>}
                                                </div>

                                                <div className="mt-4 mb-3">
                                                    {/* <button className="d-send-btn-1 rounded-pill" type='submit'>
                                                        Sign Up
                                                    </button> */}
                                                    {isLoading == true ? (
                                                        <button className="d-send-btn-1 rounded-pill" type="button">
                                                            <Spinner animation="border" />
                                                        </button>
                                                    ) : (
                                                        <div className='mb-5'>
                                                            <button className="d-send-btn-1 rounded-pill" type='submit'>
                                                                Sign Up
                                                            </button>
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="mb-3 d-flex align-items-center gap-2 justify-content-center">
                                                    <label className="form-label mb-0">Already have an Account?</label>
                                                    <Link to="/signin" className='link-s-l-1'>Sign In</Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                <div className='card dash-card-1'>
                                    <div className='card-body py-5'>

                                        <h5 className="dash-head-1  text-center">Enter OTP</h5>
                                        <p className='dash-trans-txt-2 text-center mb-4'>An Email containing the OTP has been sent to <span className='dash-head'>{userEmail}</span></p>
                                        <div className='px-lg-4 profile-section-one '>
                                            <OtpInput
                                                value={verifyOTP}
                                                onChange={setverifyOTP}
                                                numInputs={6}
                                                isInputNum={true}
                                                renderSeparator={<span style={{ margin: '0 10px' }}> -</span>}
                                                renderInput={(props) => (
                                                    <input
                                                        {...props}
                                                        className={`form-control ${props.className}`}
                                                        style={{
                                                            textAlign: 'center',
                                                            flexGrow: 1,
                                                            width: 'auto',
                                                            minWidth: '50px',
                                                            height: '50px'
                                                        }}
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        // Prevent non-numeric input
                                                        onKeyPress={(e) => {
                                                            if (!/[0-9]/.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                )}
                                                containerStyle={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                }}
                                            />
                                            <div className='text-end'>
                                                {timer > 0 ? (
                                                    <span>Resend OTP in <span className='dash-head'>{formatTime(timer)}</span></span>
                                                ) : (
                                                    <button className='dash-trans-txt-2 mt-1 bg-transparent border-0' onClick={handleResendOTP} disabled={loading}>
                                                        {loading ? 'Sending OTP...' : 'Resend OTP'}
                                                    </button>
                                                )}
                                            </div>
                                            <button className='d-send-btn-gr-1 mt-4' onClick={handleOtpSubmit}>
                                                <div className='d-send-btn-gr-1-1'>
                                                    Submit
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* <!-- Terms Condition Modal --> */}
                <div class="modal fade" id="termsandconditions" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="termsandconditionsLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header border-0">
                                {/* <h1 class="modal-title fs-5" id="termsandconditionsLabel"></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <h2 className="dash-head-1 text-center">Terms & Conditions</h2>
                                <div className="privacy-content container pb-5">
                                    <p className='dash-trans-txt-2'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit.
                                        Nulla porttitor accumsan tincidunt. Nulla quis lorem ut libero malesuada feugiat.
                                    </p>

                                    <h4 className="dash-head-1 my-2">1. Information Collection</h4>
                                    <p className='dash-trans-txt-2'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus suscipit tortor eget felis porttitor volutpat.
                                        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec velit neque, auctor sit amet.
                                    </p>

                                    <h4 className="dash-head-1 my-2">2. Use of Information</h4>
                                    <p className='dash-trans-txt-2'>
                                        Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt
                                        nibh pulvinar a. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                                    </p>

                                    <h4 className="dash-head-1 my-2">3. Sharing of Information</h4>
                                    <p className='dash-trans-txt-2'>
                                        Pellentesque in ipsum id orci porta dapibus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.
                                        Proin eget tortor risus. Donec sollicitudin molestie malesuada.
                                    </p>

                                    <h4 className="dash-head-1 my-2">4. Security</h4>
                                    <p className='dash-trans-txt-2'>
                                        Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Cras ultricies ligula sed magna dictum porta.
                                        Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                                    </p>

                                    <h4 className="dash-head-1 my-2">5. Changes to This Policy</h4>
                                    <p className='dash-trans-txt-2'>
                                        Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Praesent sapien massa,
                                        convallis a pellentesque nec, egestas non nisi.
                                    </p>

                                    <p className='dash-trans-txt-2'>
                                        If you have any questions regarding our privacy policy, feel free to contact us.
                                    </p>
                                </div>
                            </div>
                            <div class="modal-footer border-0">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Privacy Modal --> */}
                <div class="modal fade" id="privacy" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="privacyLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header border-0">
                                {/* <h1 class="modal-title fs-5" id="privacyLabel"></h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <h2 className="dash-head-1 text-center">Privacy Policy</h2>
                                <div className="privacy-content container pb-5">
                                    <p className='dash-trans-txt-2'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit.
                                        Nulla porttitor accumsan tincidunt. Nulla quis lorem ut libero malesuada feugiat.
                                    </p>

                                    <h4 className="dash-head-1 my-2">1. Information Collection</h4>
                                    <p className='dash-trans-txt-2'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus suscipit tortor eget felis porttitor volutpat.
                                        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec velit neque, auctor sit amet.
                                    </p>

                                    <h4 className="dash-head-1 my-2">2. Use of Information</h4>
                                    <p className='dash-trans-txt-2'>
                                        Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Mauris blandit aliquet elit, eget tincidunt
                                        nibh pulvinar a. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                                    </p>

                                    <h4 className="dash-head-1 my-2">3. Sharing of Information</h4>
                                    <p className='dash-trans-txt-2'>
                                        Pellentesque in ipsum id orci porta dapibus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.
                                        Proin eget tortor risus. Donec sollicitudin molestie malesuada.
                                    </p>

                                    <h4 className="dash-head-1 my-2">4. Security</h4>
                                    <p className='dash-trans-txt-2'>
                                        Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie malesuada. Cras ultricies ligula sed magna dictum porta.
                                        Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                                    </p>

                                    <h4 className="dash-head-1 my-2">5. Changes to This Policy</h4>
                                    <p className='dash-trans-txt-2'>
                                        Cras ultricies ligula sed magna dictum porta. Donec sollicitudin molestie malesuada. Praesent sapien massa,
                                        convallis a pellentesque nec, egestas non nisi.
                                    </p>

                                    <p className='dash-trans-txt-2'>
                                        If you have any questions regarding our privacy policy, feel free to contact us.
                                    </p>
                                </div>
                            </div>
                            <div class="modal-footer border-0">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Signup;
